import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import ImageCarousel from '../../components/ImageCarousel';
import ReviewSection from '../../components/ReviewSection';
import DataSection from '../../components/DataSection';
import imageObject from '../../utils/images';
import dictionary from './words';
import MyContext from '../../middleware/context';
import { withRouter } from 'react-router-dom';
import './style.css';

const ImageSources = {
  first: imageObject.homeImageOne,
  second: imageObject.homeImageTwo,
  third: imageObject.homeImageThree
};

const ImageSourcesAncol = {
  first: imageObject.homeImageAncolOne,
  second: imageObject.homeImageAncolTwo,
  third: imageObject.homeImageAncolThree,
  fourth: imageObject.homeImageAncolFour,
  fifth: imageObject.homeImageAncolFive,
  sixth: imageObject.homeImageAncolSix,
  seventh: imageObject.homeImageAncolSeven,
  eigth: imageObject.homeImageAncolEight,
  ninth: imageObject.homeImageAncolNine,
  tenth: imageObject.homeImageAncolTen,
  eleventh: imageObject.homeImageAncolEleven
};

const ImageSourcesReviewers = {
  firstUser: imageObject.userOne,
  secondUser: imageObject.userTwo,
  thirdUser: imageObject.userThree,
  firstCompany: imageObject.companyOne,
  secondCompany: imageObject.companyTwo,
  thirdCompany: imageObject.companyThree
};

const ImageSourcesFeature = {
  firstIcon: imageObject.iconOne,
  secondIcon: imageObject.iconTwo
};

const Home = props => {
  const { title, history } = props;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const CarouselContent = [
    {
      title: 'The Best Logistics Service Provider',
      subtitle: 'Your reliable warehousing partner',
      image: imageObject.first,
      action: (
        <Button className='block' onClick={() => history.push('/contact-us')}>
          Contact Us
        </Button>
      )
    },
    {
      title: 'We Provide a Variety of Services',
      subtitle:
        'We can Cater to Your Every Logistical Need and We are the best in what we do',
      image: imageObject.warehouseInside
    },
    {
      title: 'We Keep Your Goods Safe',
      subtitle:
        'We will handle your goods from loading dock to storage, handled by our best and well trained staff',
      image: imageObject.warehouseFront
    }
  ];
  const CarouselContentAncol = [
    {
      title: 'The Best Logistics Service Provider',
      subtitle: 'Your reliable warehousing partner',
      image: ImageSourcesAncol.first,
      action: (
        <Button className='block' onClick={() => history.push('/contact-us')}>
          Contact Us
        </Button>
      )
    },
    {
      title: 'We Provide a Variety of Services',
      subtitle:
        'We can Cater to Your Every Logistical Need and We are the best in what we do',
      image: ImageSourcesAncol.second
    },
    {
      title: 'We Keep Your Goods Safe',
      subtitle:
        'We will handle your goods from loading dock to storage, handled by our best and well trained staff',
      image: ImageSourcesAncol.third
    },
    {
      title: '',
      subtitle: '',
      image: ImageSourcesAncol.fourth
    },
    {
      title: '',
      subtitle: '',
      image: ImageSourcesAncol.fifth
    },
    {
      title: '',
      subtitle: '',
      image: ImageSourcesAncol.sixth
    },
    {
      title: '',
      subtitle: '',
      image: ImageSourcesAncol.seventh
    },
    {
      title: '',
      subtitle: '',
      image: ImageSourcesAncol.eigth
    },
    {
      title: '',
      subtitle: '',
      image: ImageSourcesAncol.ninth
    },
    {
      title: '',
      subtitle: '',
      image: ImageSourcesAncol.tenth
    },
    {
      title: '',
      subtitle: '',
      image: ImageSourcesAncol.eleventh
    }
  ];

  const clientReviews = [
    {
      name: 'Linda Anand',
      role: 'General Manager',
      companyName: 'Company Name',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In neque imperdiet non adipiscing magna.',
      userImage: ImageSourcesReviewers.firstUser,
      companyImage: ImageSourcesReviewers.firstCompany
    },
    {
      name: 'Frank Kinney',
      role: 'Director',
      companyName: 'Company Name',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In neque imperdiet non adipiscing magna.',
      userImage: ImageSourcesReviewers.secondUser,
      companyImage: ImageSourcesReviewers.secondCompany
    },
    {
      name: 'David Nelson',
      role: 'Purchasing Manager',
      companyName: 'Company Name',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In neque imperdiet non adipiscing magna.',
      userImage: ImageSourcesReviewers.thirdUser,
      companyImage: ImageSourcesReviewers.thirdCompany
    }
  ];

  const features = [
    {
      name: 'Land Size',
      nameCont: 'We Managed',
      description: dictionary.landsizeDescription,
      image: ImageSourcesFeature.firstIcon,
      total: '7000m2'
    },
    {
      name: 'Containers',
      nameCont: 'We Processed',
      description: dictionary.containersDescription,
      image: ImageSourcesFeature.secondIcon,
      total: 4553
    }
  ];

  useEffect(() => {
    document.title = `${title} | ATS`;
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleWindowResize = () => {
    setScreenWidth(window.innerWidth);
  };

  return (
    <MyContext.Consumer>
      {context => {
        return (
          <div className='mt-5 text-left'>
            <ImageCarousel history={history} contents={CarouselContentAncol} />
            {/* <ReviewSection reviews={clientReviews} /> */}
            <DataSection
              features={features}
              context={context}
              dictionary={dictionary}
            />
            <Container>
              <h2>{dictionary.aboutUsTitle[context.state.currentLanguage]}</h2>
              <Row className='mb-5'>
                <Col md={8} sm={7}>
                  <p>{dictionary.aboutUs1[context.state.currentLanguage]} </p>
                  <p>{dictionary.aboutUs2[context.state.currentLanguage]} </p>
                </Col>
                <Col md={4} sm={5}>
                  <Image
                    fluid
                    thumbnail
                    src={ImageSources.first}
                    height={200}
                  />
                </Col>
              </Row>
              {screenWidth > 576 ? (
                <React.Fragment>
                  <h2 className='text-right'>
                    {dictionary.visionTitle[context.state.currentLanguage]}
                  </h2>
                  <Row className='mb-5'>
                    <Col md={4} sm={5}>
                      <Image
                        fluid
                        thumbnail
                        src={ImageSources.second}
                        height={200}
                      />
                    </Col>
                    <Col md={8} sm={7}>
                      <p className='vision'>
                        {dictionary.vision[
                          context.state.currentLanguage
                        ].toUpperCase()}{' '}
                      </p>
                    </Col>
                  </Row>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h2 className='text-left'>
                    {dictionary.visionTitle[context.state.currentLanguage]}
                  </h2>
                  <Row className='mb-5'>
                    <Col md={8} sm={7}>
                      <p className='vision'>
                        {dictionary.vision[
                          context.state.currentLanguage
                        ].toUpperCase()}{' '}
                      </p>
                    </Col>
                    <Col md={4} sm={5}>
                      <Image
                        fluid
                        thumbnail
                        src={ImageSources.second}
                        height={200}
                      />
                    </Col>
                  </Row>
                </React.Fragment>
              )}
              <h2>{dictionary.missionTitle[context.state.currentLanguage]}</h2>
              <Row className='mb-5'>
                <Col md={8} sm={7}>
                  <ol className='text-left'>
                    {dictionary.mission[context.state.currentLanguage].map(
                      (text, key) => {
                        return <li key={key}>{text}</li>;
                      }
                    )}
                  </ol>
                </Col>
                <Col md={4} sm={5}>
                  <Image
                    fluid
                    thumbnail
                    src={ImageSources.third}
                    height={200}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        );
      }}
    </MyContext.Consumer>
  );
};

export default withRouter(Home);
